import React from "react";

export default function Statement({data}) {
    return (
        <div className="row">
            <div className="col-12">
                <h1 className="text-center">Заявление веры</h1>
                <p>
                    1) Мы веруем, что канонические книги Библии (тридцать девять книг Ветхого Завета и двадцать семь книг Нового Завета) являются непогрешимым Словом Божьим. Священное Писание — источник нашей веры и высший авторитет в вопросах доктрины и христианской жизни.
                </p>
                <p>
                    2) Мы веруем в единого Бога, сотворившего все видимое и невидимое, безначального, премудрого, вездесущего, всеведущего и всемогущего, существующего в трех лицах: Отце, и Сыне, и Духе Святом.
                </p>
                <p>
                    3) Мы веруем, что Иисус Христос — Сын Божий — был зачат Святым Духом и рожден от девы. Иисус Христос был распят, умер за грехи наши и воскрес из мертвых. Затем Он вознесся на небо, воссел одесную Бога и сделался единым Посредником между Богом и человеками.
                </p>
                <p>
                    4) Мы веруем, что Дух Святой обличает во грехе, возрождает уверовавших, живет в них, наставляет, исполняет силой для благочестивой жизни, наделяет дарами для служения в Церкви.
                </p>
                <p>
                    5) Мы веруем, что человек был сотворен безгрешным по образу и подобию Божию, но согрешил и умер духовно. Через первого человека — Адама — все человечество унаследовало грех и смерть. Человек может спастись верою через действие Слова Божьего и возрождение Духом Святым. Он нуждается в постоянном освящении в течение всей жизни.
                </p>
                <p>
                    6) Мы веруем, что Иисус Христос создал Церковь и Он является Главою Церкви. Все, уверовавшие во Христа, крещены Духом Святым в Тело Христово и являются членами Церкви Христовой. Поместная церковь есть собрание возрожденных душ, добровольно объединившихся для совместного служения Господу и удовлетворения своих духовных нужд. Условием для вступления в члены поместной церкви являются вера, покаяние и возрождение. Об этом человек свидетельствует перед Богом и церковью через устное исповедание, личную жизнь и водное крещение.
                </p>
                <p>
                    7) Мы веруем, что основная задача Церкви, согласно Великому Поручению Христа, провозглашение Евангелия посредством благовестия и ученичества.
                </p>
                <p>
                    8) Мы веруем, что водное крещение и Вечеря Господня (хлебопреломление) – заповеди, которые должна соблюдать церковь. Водное крещение совершается над теми, кто уверовал в Иисуса как в своего личного Спасителя и пережил рождение свыше. В Вечере Господней принимают участие те, кто вступил в члены церкви через крещение по вере. Крещение и Вечеря без спасающей веры не обеспечивают спасения.
                </p>
                <p>
                    9) Мы веруем во Второе Пришествие Иисуса Христа, Который явится видимым образом во славе для суда над живыми и мертвыми.
                </p>
                <p>
                    10) Мы веруем в телесное воскресение из мертвых: для верующих — к славе и вечному блаженству, для неверующих — к вечному осуждению и пребыванию в муках.
                </p>
            </div>
        </div>
    )
}
